import React from "react";
import tw from "twin.macro";
import Status from "../assets/images/swimlanes.png";
import StatusMobile from "../assets/images/swimlanesMobile.png";

const TextContent = tw.div`justify-center items-center`;
const RightText = tw.div`text-xl md:text-2xl mt-16 ml-8`;
const Solution = tw.div`w-11/12 mt-4`;
const LeftAndRight = tw.div`flex justify-between`;
const CTAText = tw.div`text-xl pt-5`;
const PrimaryButtonBase = tw.button`px-1 py-1 text-primary-500 border-3 border-primary-500 border-solid hover:bg-primary-500 hover:text-gray-100 focus:outline-none`;
const PrimaryButton = tw(
    PrimaryButtonBase,
)`mt-2 text-2xl font-bold inline-block rounded-xl`;
const primaryButtonUrl = "https://production.uclusion.com/?utm_campaign=team#signup";

const BelowTheFoldStatus = (properties) => {
    const { isMobile } = properties;
    return (
        <TextContent style={{display:"flex", flexDirection:"column"}}>
            <Solution>
                <LeftAndRight>
                    <img src={isMobile ? StatusMobile : Status} alt='Status'
                         style={{maxHeight: isMobile ? undefined : '42rem'}}/>
                    <RightText>
                        You can tell at a glance what needs assistance or has an estimate due.
                    </RightText>
                </LeftAndRight>
                <CTAText style={{display: isMobile ? undefined : 'none'}}>
                    See status at a glance in the <PrimaryButton as="a" href={primaryButtonUrl}>sandbox demo</PrimaryButton> after
                    free sign up.
                </CTAText>
            </Solution>
        </TextContent>
    );
};

export default BelowTheFoldStatus;