import React from "react";
import tw from "twin.macro";
import Question from "../assets/images/question.png";
import QuestionMobile from "../assets/images/questionMobile.png";

const TextContent = tw.div`justify-center items-center`;
const Problem = tw.div`w-11/12`;
const Solution = tw.div`w-11/12 mt-5`;
const CTAText = tw.div`text-xl md:text-2xl pt-10`;
const LeftAndRight = tw.div`flex justify-between`;
const LeftText = tw.div`text-xl mt-16 mr-4`;
const SolutionText = tw.div`w-11/12 text-xl md:text-2xl`;
const PrimaryButtonBase = tw.button`px-1 py-1 text-primary-500 border-3 border-primary-500 border-solid hover:bg-primary-500 hover:text-gray-100 focus:outline-none`;
const PrimaryButton = tw(
    PrimaryButtonBase,
)`mt-2 text-xl font-bold inline-block rounded-xl`;
const primaryButtonUrl = "https://production.uclusion.com/?utm_campaign=team#signup";

const BelowTheFoldIssues = (properties) => {
    const { isMobile } = properties;
    return (
        <TextContent style={{display: "flex", flexDirection: "column"}}>
            <Problem>
                <SolutionText style={{display: isMobile ? 'none' : undefined}}>
                    Questions and suggestions are first class citizens with voting.
                </SolutionText>
            </Problem>
            <Solution>
                <LeftAndRight>
                    <LeftText style={{display: isMobile ? 'none' : undefined}}>If a question is opened by an assignee
                        the job status automatically changes.</LeftText>
                    <img src={isMobile ? QuestionMobile : Question} alt='Question'
                         style={{maxHeight: isMobile ? undefined : '34rem', }}/>
                </LeftAndRight>
                <CTAText>
                    See <em>structured comments</em> in the <PrimaryButton as="a" href={primaryButtonUrl}>sandbox
                    demo</PrimaryButton> after
                    free sign up.
                </CTAText>
            </Solution>
        </TextContent>
    );
};

export default BelowTheFoldIssues;
