import React from 'react';
import {Route, Switch} from "react-router-dom";
import "../../desktop.css";
import Footer from "../../../shared/MiniCenteredFooter";
import Header from "../../../shared/SiteHeader";
import AboutUs from "../../../shared/AboutUs";
import tw from "twin.macro";
import Home from "./Home";

const Page = tw.div`h-screen w-full relative`;
const Container = tw.div`-z-50 mt-20 mb-20`;

function TeamApp() {
    return (
        <Switch>
            <Route exact path="/">
                <Home />
            </Route>
            <Route path="/aboutus">
                <Page>
                    <Header/>
                    <Container>
                        <AboutUs/>
                    </Container>
                    <Footer/>
                </Page>
            </Route>
        </Switch>
    );
}

export default TeamApp;