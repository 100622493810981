import React from 'react';
import Header from '../../../shared/SiteHeader';
import AboveTheFold from "../../../shared/AboveTheFold";
import Pricing from "../../../shared/Pricing";
import Footer from "../../../shared/MiniCenteredFooter";
import BelowTheFold from "../../../shared/BelowTheFold";
import {ArrowDownwardOutlined} from "@material-ui/icons";

function MobileHome() {
    return (
        <>
            <Header />
            <div>
                <AboveTheFold isMobile/>
                <div className="bouncer" style={{marginTop: '5rem', width: '100%'}}>
                    <ArrowDownwardOutlined style={{marginLeft: '49%'}}/>
                </div>
                <BelowTheFold isMobile/>
                <div style={{marginTop: '6rem'}}/>
                <Pricing/>
            </div>
            <Footer/>
        </>
    );
}

export default MobileHome;