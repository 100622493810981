import React from "react";
import tw from "twin.macro";
import JobApproval from "../assets/images/approvals.png";
import JobApprovalMobile from "../assets/images/jobApprovalMobile.png";

const TextContent = tw.div`justify-center items-center`;
const Problem = tw.div`w-11/12 mb-4`;
const Solution = tw.div`w-11/12 mt-3`;
const ProblemText = tw.div`w-11/12 text-xl md:text-2xl`;
const CTAText = tw.div`text-xl md:text-2xl mb-2 pt-10`;
const RightText = tw.div`text-xl md:text-2xl mt-16 ml-8`;
const PrimaryButtonBase = tw.button`px-1 py-1 text-primary-500 border-3 border-primary-500 border-solid hover:bg-primary-500 hover:text-gray-100 focus:outline-none`;
const PrimaryButton = tw(
    PrimaryButtonBase,
)`mt-2 text-2xl font-bold inline-block rounded-xl`;
const primaryButtonUrl = "https://production.uclusion.com/?utm_campaign=team#signup";
const LeftAndRight = tw.div`flex justify-between`;

const BelowTheFoldScrum = (properties) => {
    const { isMobile } = properties;
    return (
        <TextContent style={{display:"flex", flexDirection:"column"}}>
            <Problem>
                <ProblemText style={{display: isMobile ? 'none' : undefined}}>
                    Assigning a job sends a request to the team to approve.
                </ProblemText>
            </Problem>
            <Solution>
                <LeftAndRight>
                    <img src={isMobile ? JobApprovalMobile : JobApproval} alt='Job approval' />
                    <RightText style={{display: isMobile ? 'none' : undefined}}>
                        If a job doesn't move to approved stage the approvals expire and the approval notification is
                        resent.
                    </RightText>
                </LeftAndRight>
                <CTAText style={{paddingTop: isMobile ? '1rem' : undefined}}>
                    See all job stages in the <PrimaryButton as="a" href={primaryButtonUrl}>sandbox demo</PrimaryButton> after
                    free sign up.
                </CTAText>
            </Solution>
        </TextContent>

    );
};

export default BelowTheFoldScrum;