import {Avatar, Grid} from "@material-ui/core";
import David from "../assets/images/david.jpg";
import Ben from "../assets/images/ben.jpg";
import React from "react";
import tw from "twin.macro";
import SingleCol from "./SingleCol";

const Container = tw.div`flex justify-center items-center`;

function AboutUs() {
    return (
        <>
            <Container>
                <Grid
                    className="mainGrid"
                    container
                    direction="column"
                    alignItems="center"
                >
                    <Grid
                        item
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        className="areaContainer"
                    >
                        <Grid item className="sectionContainer">
                            <div className="subpageBlock">
                                <div style={{textAlign: 'center'}}>
                                    <Avatar src={David} style={{width: '9rem', height: '9rem', margin: "auto"}}/>
                                    <h3 style={{paddingTop: '0.5rem', marginTop: '0.6rem', margin: "auto"}}>
                                        David Israel
                                    </h3>
                                    <h5 style={{padding: '0', marginBottom: '0', margin: "auto"}}>Co-Founder</h5>
                                </div>
                                <div style={{textAlign: 'center'}}>
                                    <Avatar src={Ben} style={{width: '9rem', height: '9rem', margin: "auto"}}/>
                                    <h3 style={{paddingTop: '0.5rem', marginTop: '0.6rem', margin: "auto"}}>
                                        Ben Follis
                                    </h3>
                                    <h5 style={{padding: '0', marginBottom: '0', margin: "auto"}}>Co-Founder</h5>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <SingleCol />
        </>
    );
}

export default AboutUs;