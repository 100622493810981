import React, { useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";

const Column = tw.div`flex flex-col items-center`;
const FAQSContainer = tw.dl`mt-12 max-w-4xl relative`;
const FAQ = tw.div`cursor-pointer select-none mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-primary-500 hover:text-gray-900 bg-gray-200 hover:bg-gray-300 transition duration-300`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = motion(styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`);
const Answer = motion(tw.dd`text-sm sm:text-base leading-relaxed`);

const AboutUsQs = ({
  faqs = [
    {
      question: "How did Uclusion start?",
      answer:
          "In 2018 Ben and David were both working for Adobe Sign and sick of feature factory work."
    },
    {
      question: "Where did the idea come from?",
      answer:
          "At first we thought the solution to feature factory was a tool for customer input, so that features would be meaningful. However in our first year we realized that, for various reasons, it was up to the development team to prevent the stream of unused features."
    },
    {
      question: "Why has it taken so long to develop Uclusion?",
      answer:
        "There were only two of us plus the occasional contractor and even our work on Uclusion was in half the years part time. Plus this is software for developers who are instantly bothered by half baked or incomplete features."
    },
    {
      question: "How can Uclusion claim to replace Scrum, Jira, and Slack for development?",
      answer:
        <>Honestly these are very old tools that don't do much for developers anyway. You can read more about that in <a style={{color: 'blue'}} href="https://blog.uclusion.com/startup/2024/05/30/work-env.html" target="_blank" rel="noopener noreferrer" onClick={(event) => event.stopPropagation()}>this blog</a>.</>
    },
    {
      question: "How can we be certain Uclusion is mature software?",
      answer:
          <>Sign up for free and see and play around inside the sandbox demo of a team already using Uclusion for software development. You can also check out <a style={{color: 'blue'}} href="https://github.com/Uclusion/uclusion_web_ui" target="_blank" rel="noopener noreferrer" onClick={(event) => event.stopPropagation()}>our public UI repo</a>.</>
    },
    {
      question: "Does Uclusion have competition?",
      answer:
        "We have not found any other system designed to help developers deliver value without meetings. Other task management requires meetings and seems concerned only with work completing and not if it will be useful."
    },
  ]
}) => {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
      <Column>
        <FAQSContainer>
          {faqs.map((faq, index) => (
            <FAQ
              key={index}
              onClick={() => {
                toggleQuestion(index);
              }}
              className="group"
            >
              <Question>
                <QuestionText>{faq.question}</QuestionText>
                <QuestionToggleIcon
                  variants={{
                    collapsed: { rotate: 0 },
                    open: { rotate: -180 }
                  }}
                  initial="collapsed"
                  animate={activeQuestionIndex === index ? "open" : "collapsed"}
                  transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
                >
                  <ChevronDownIcon />
                </QuestionToggleIcon>
              </Question>
              <Answer
                variants={{
                  open: { opacity: 1, height: "auto", marginTop: "16px" },
                  collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                }}
                initial="collapsed"
                animate={activeQuestionIndex === index ? "open" : "collapsed"}
                transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
              >
                {faq.answer}
              </Answer>
            </FAQ>
          ))}
        </FAQSContainer>
      </Column>
  );
};

export default AboutUsQs;
