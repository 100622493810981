import React from "react";
import tw from "twin.macro";
import Discussion from "../assets/images/discussion.png";
import BugsMobile from "../assets/images/bugsMobile.png";

const TextContent = tw.div`justify-center items-center mr-2 ml-5`;
const Problem = tw.div`w-11/12 mb-5`;
const Solution = tw.div`w-11/12 mt-4`;
const ProblemText = tw.div`w-11/12 text-xl md:text-2xl`;
const CTAText = tw.div`text-xl md:text-2xl mb-2 pt-10`;
const PrimaryButtonBase = tw.button`px-1 py-1 text-primary-500 border-3 border-primary-500 border-solid hover:bg-primary-500 hover:text-gray-100 focus:outline-none`;
const PrimaryButton = tw(
    PrimaryButtonBase,
)`mt-2 text-2xl font-bold inline-block rounded-xl`;
const primaryButtonUrl = "https://production.uclusion.com/?utm_campaign=team#signup";

const BelowTheFoldScale = (properties) => {
    const { isMobile } = properties;
    return (
        <TextContent style={{display:"flex", flexDirection:"column"}}>
            <Problem>
                <ProblemText style={{display: isMobile ? 'none' : undefined}}>
                    Why use chat and issue trackers with a customer when a private workspace does more?
                </ProblemText>
            </Problem>
            <Solution>
                <img src={isMobile ? BugsMobile : Discussion} alt='Bugs'
                     style={{maxHeight: isMobile ? undefined : '32rem'}}/>
                <CTAText style={{paddingTop: isMobile ? '1rem' : undefined}}>
                    See the power of a workspace in the <PrimaryButton as="a" href={primaryButtonUrl}>sandbox demo</PrimaryButton> after
                    free sign up.
                </CTAText>
            </Solution>
        </TextContent>
    );
};

export default BelowTheFoldScale;