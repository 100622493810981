import React from 'react';
import {Route, Switch} from "react-router-dom";
import '../../mobile.css';
import Footer from "../../../shared/MiniCenteredFooter";
import Header from "../../../shared/SiteHeader";
import MobileHome from "./Home";
import AboutUs from "../../../shared/AboutUs";
import tw from "twin.macro";

const Container = tw.div`mr-2 ml-2 -z-50 mt-20 mb-20`;

function TeamApp(){
    return (
        <Switch>
            <Route exact path="/">
                <MobileHome />
            </Route>
            <Route path="/aboutus">
                <Header />
                <Container>
                    <AboutUs />
                </Container>
                <Footer />
            </Route>
        </Switch>
    );
}

export default TeamApp;