import React from 'react';

import ScrollToTop from './shared/ScrollToTop'
import {
    BrowserRouter as Router,

} from "react-router-dom";
import './App.css';
import DesktopTeamApp from "./desktop/components/Teams/TeamApp";
import MobileTeamApp from "./mobile/components/Teams/TeamApp";
import {useMediaQuery, useTheme} from "@material-ui/core";

function App(props) {
    const {campaign} = props;
    const theme = useTheme();
    const mobileLayout = useMediaQuery(theme.breakpoints.down('sm'));

    function getAppForLayout() {
        const utm = campaign || 'team';
        if (mobileLayout) {
            return <MobileTeamApp utm={utm}/>
        }
        return (<DesktopTeamApp utm={utm}/>);
    }

    return (
            <Router>
                <ScrollToTop>
                    <div className="uclusion-app">
                        {getAppForLayout()}
                    </div>
                </ScrollToTop>
            </Router>
    );
}

export default App;
